<template>
  <div id="printMe">
    <div class="container bg-white">
      <table class="table table-borderless mt-3 calibri_text" style="margin-bottom: 0">
        <tbody class="border-top">
          <tr>
            <td class="customborder" rowspan="2" style="max-width: 80px">
              <img src="media/logos/logo-petrokimia-gresik.jpg" style="width: 100%" alt="" />
            </td>
            <td class="text-center customborder font-judul" style="max-width: 350px">
              <strong
                >DEPARTEMEN INSPEKSI TEKNIK STATIK<br />
                STAF INSPEKSI TEKNIK
                {{ dataprint.diketahui.pabrik.toUpperCase() }}</strong
              >
            </td>
            <td class="text-left">Tanggal</td>
            <td class="text-left">:</td>
            <td>{{ dataprint.tanggal }}</td>
          </tr>

          <tr>
            <td
              class="text-center customborder font-judul"
              style="font-size: 14px; max-width: 350px"
            >
              <strong>HASIL PEMERIKSAAN DAN REKOMENDASI</strong>
            </td>
            <td>Halaman</td>
            <td>:</td>
            <td>1 dari 1</td>
          </tr>
        </tbody>
      </table>
      <table id="second-table" class="table table-borderless" style="margin: 0">
        <tbody class="border-top">
          <tr class="border-left">
            <td style="width: 120px; font-size: 12px" class="pl-2">
              No Laporan
            </td>
            <td style="width: 2px; font-size: 12px">:</td>
            <td style="font-size: 12px">{{ dataprint.no_report }}</td>
            <td
              class="no-middle border-bottom border-right"
              style="width: 500px; font-size: 12px"
              rowspan="4"
            >
              <div class="row">
                <div class="col-5">Ditujukan Kepada :</div>
                <div class="col-7">
                  <ol>
                    <li v-for="(item, index) in dataprint.issued_to" :key="index">
                      {{ item }}
                    </li>
                  </ol>
                </div>
              </div>
            </td>
            <!-- <td class="no-middle border-bottom border-right" style="200px" rowspan="4">
                        
                    </td> -->
          </tr>
          <tr class="border-left">
            <td class="pl-2" style="width: 100px; font-size: 12px">
              No Peralatan
            </td>
            <td style="width: 10px; font-size: 12px">:</td>
            <td style="width: 580px; font-size: 12px">
              {{ dataprint.nomor_equipment }}
            </td>
          </tr>
          <tr class="border-left">
            <td class="pl-2">No Notifikasi</td>
            <td>:</td>
            <td></td>
          </tr>
          <tr class="border-left border-bottom">
            <td class="pl-2" style="width: 100px">Unit</td>
            <td>:</td>
            <td>{{ dataprint.unit }}</td>
          </tr>
        </tbody>
      </table>
      <table id="third-table" class="table table-borderless calibri_text" style="margin: 0">
        <thead class="text-center border-left border-right">
          <tr>
            <th style="font-size: 12px !important">NO</th>
            <th class="border-left" style="width: 150px; font-size: 12px !important">
              OBYEK
            </th>
            <th class="border-left" style="font-size: 12px !important">
              METODE
            </th>
            <th class="border-left" style="font-size: 12px !important">
              HASIL PEMERIKSAAN
            </th>
            <th class="border-left" style="font-size: 12px !important">
              REKOMENDASI
            </th>
            <th class="border-left" style="font-size: 12px !important">
              PELAKSANAAN PERBAIKAN
            </th>
            <th class="border-left" style="font-size: 12px !important">
              REKOMENDASI YANG AKAN DATANG
            </th>
          </tr>
        </thead>
        <tbody class="customborder">
          <tr v-for="(item, index) in dataprint.items" :key="index">
            <td class="border-left align-top text-center">
              <p v-html="index + 1" style="font-size: 12px !important"></p>
            </td>
            <td class="border-left align-top text-center">
              <p v-html="item.part" style="font-size: 12px !important"></p>
            </td>
            <td class="border-left align-top text-center">
              <p v-html="item.method" style="font-size: 12px !important"></p>
            </td>
            <td class="border-left align-top text-justify">
              <div v-html="item.inspection_result" style="font-size: 12px !important"></div>
            </td>
            <td class="border-left align-top text-justify">
              <div
                class="printstyle"
                v-html="item.recomendation"
                style="font-size: 12px !important"
              ></div>
            </td>
            <td class="border-left align-top text-justify">
              <div v-html="item.repair_taken" style="font-size: 12px !important"></div>
            </td>
            <td class="border-left border-right align-top text-justify">
              <div v-html="item.future_recomendation" style="font-size: 12px !important"></div>
            </td>
          </tr>
        </tbody>
      </table>

      <table id="fifth-table" class="table table-borderless calibri_text" style="margin: 0">
        <tbody class="border-right border-left border-top">
          <tr>
            <td
              :colspan="dataprint.co_inspector.length + 1"
              class="text-center no-middle"
              style="height: 60px"
            >
              Inspektor
            </td>
            <td class="no-middle">
              Diketahui/Disahkan oleh <br />
              Group Leader {{ dataprint.diketahui.pabrik }}
            </td>
          </tr>
          <tr>
            <!--<td class="text-left" v-if="dataprint.co_inspector.length"></td>
              <td class="text-center" v-else>ttd</td>!-->
            <td class="text-center">ttd</td>
            <td
              class="align-top text-center"
              v-for="(item, index) in dataprint.co_inspector"
              :key="index"
            >
              ttd
            </td>
            <td class="text-left">ttd</td>
          </tr>
          <tr>
            <!--<td class="text-left" v-if="dataprint.co_inspector.length"></td>
              <td class="text-center" v-else>
                {{ dataprint.diketahui.inspector_name }}
              </td>!-->
            <td class="text-center" style="padding-top:6rem">
              {{ dataprint.diketahui.inspector_name }}
            </td>
            <td
              class="align-top text-center"
              style="padding-top:6rem"
              v-for="(item, index) in dataprint.co_inspector"
              :key="index"
            >
              {{ item }}
            </td>
            <td class="text-left" style="padding-top:6rem">
              {{ dataprint.diketahui.nama }}
            </td>
          </tr>
        </tbody>
      </table>
      <table id="fourth-table" class="table table-borderless calibri_text" style="margin: 0">
        <tbody class="border-right border-left border-bottom">
          <tr>
            <td>
              <b-row v-for="(item, index) in dataprint.items" :key="index">
                <b-col>
                  <b-row cols="3">
                    <b-col
                      class="text-center"
                      v-for="(img, index_img) in JSON.parse(item.img)"
                      :key="index + '+' + index_img"
                    >
                      <b-img
                        style="max-height: 200px"
                        thumbnail
                        fluid
                        :src="urlStorage + '/images/report/' + img"
                      ></b-img>
                      <br />
                      <p>
                        {{ dataprint.items[index].image_keterangan[index_img] }}
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </td>
          </tr>
        </tbody>
      </table>
      <b-row>
        <b-col>&nbsp;</b-col>
        <b-col><vue-qr :text="urlqr" :size="200"></vue-qr></b-col>
        <b-col>&nbsp;</b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service'
import VueQr from 'vue-qr'
import * as moment from 'moment'
import 'moment/locale/id'
import { mapGetters } from 'vuex'

export default {
  components: {
    VueQr,
  },

  data() {
    return {
      urlqr: '',
      dataprint: {
        no_report: null,
        nomor_equipment: null,
        unit: null,
        issued_to: [],
        items: [],
        co_inspector: [],
        diketahui: {
          pabrik: 'Pabrik',
          nama: null,
          inspector_name: null,
        },
      },
    }
  },

  created() {
    const self = this
    ApiService.get('/report/create/edit/' + self.$route.params.id)
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.urlqr =
            'http://syspek.petrokimia-gresik.com/#/report/edit/report/' + self.$route.params.id
          self.dataprint.tanggal = self.momentDate(data.data[0].date)
          self.dataprint.no_report = data.data[0].no_report
          self.dataprint.nomor_equipment = data.data[0].nomor_equipment
          self.dataprint.unit = data.data[0].nama_plant
          self.dataprint.diketahui.pabrik = data.data[0].nama_pabrik
          self.dataprint.diketahui.nama = data.data[0].group_leader
          self.dataprint.diketahui.inspector_name = data.data[0].inspector_name

          const issued_to = JSON.parse(data.data[0].issued_to)
          self.dataprint.issued_to.splice(0, self.dataprint.issued_to.length)
          for (let i = 0; i < issued_to.length; i++) {
            const o_issued_to = JSON.parse(issued_to[i])
            self.dataprint.issued_to.push(o_issued_to.label)
          }

          if (data.data[0].co_inspector != 'null') {
            const co_inspector = JSON.parse(data.data[0].co_inspector)
            self.dataprint.co_inspector.splice(0, self.dataprint.co_inspector.length)
            for (let i = 0; i < co_inspector.length; i++) {
              const o_co_inspector = JSON.parse(co_inspector[i])
              self.dataprint.co_inspector.push(o_co_inspector.label)
            }
          }

          var item = data.data
          self.dataprint.items.splice(0, self.dataprint.items.length)

          for (let i = 0; i < item.length; i++) {
            var ket = ''
            if (item[i].image_keterangan == null) {
              ket = ''
            } else {
              ket = JSON.parse(item[i].image_keterangan)
            }
            self.dataprint.items.push({
              part: item[i].nama_part,
              recomendation: item[i].recomendation,
              repair_taken: item[i].repair_taken,
              inspection_result: item[i].inspection_result,
              future_recomendation: item[i].future_recomendation,
              method: item[i].method,
              img: item[i].image,
              image_keterangan: ket,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })
  },

  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: 'login' })
    }
  },

  computed:{
    ...mapGetters(['isAuthenticated']),
  },

  methods: {
    momentDate: function(date) {
      moment.locale('id')
      return moment(date, 'YYYY-MM-DD LTS').format('DD MMM YYYY')
    },
  },
}
</script>
